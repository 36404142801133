import React from 'react';

import styles from './ColoredDot.module.css';

const ColoredDot = ({color, blink}) => {
    return (
        <div id={styles.dot} className={blink ? styles.blinking  : undefined} style={{backgroundColor: `${color}`}}></div>
    )
}

export default ColoredDot;