import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import styles from './NoSessionsNotice.module.css';


const NoSessionsNotice = ({title, noticeText}) => {

    return (
        <div className={styles.noticeWrapper}>
            <div className={styles.noticeContent}>
                <span className={styles.icon}><FontAwesomeIcon icon={faExclamationTriangle} /></span>
                <h1 className={styles.title}>{title}</h1>
                <p className={styles.text}>{noticeText}</p>
            </div>
        </div>
    )
}

export default NoSessionsNotice;