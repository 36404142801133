import React, { useState, useContext, useEffect, useRef } from 'react';
import MuxVideo from '@mux/mux-video-react';

import { EventContext } from '../../context/event-context';
import { SocketContext } from '../../context/socket';
import { AuthContext } from '../../context/auth-context';

import  LiveLabel  from '../UIElements/mediaControls/liveLabel';
import LiveStreamControl from '../UIElements/mediaControls/LiveStreamControl';

import styles from './MuxPlayer.module.css';
import LoadingSpinnerCenter from '../UIElements/Loading/LoadingSpinnerCenter';

const MuxPlayer = ({streamData}) => {

  const { REACT_APP_MUX_DATA_KEY } = process.env
  const { REACT_APP_API_URL } = process.env
    const {userId} =  useContext(AuthContext);
    const { eventId } = useContext(EventContext);
    const socket = useContext(SocketContext);
    const videoRef = useRef(null);
    const [currentStreamData, setCurrentStreamData] = useState();
    const [isLive, setIsLive] = useState(false);
    const [playbackId, setPlaybackId] = useState()
    const [seekerValue, setSeekerValue] = useState(0);
    const [mediaDuration, setMediaDuration] = useState(0);
    const [mediaReady, setMediaReady] = useState(false);
    const [isBuffering, setIsBuffering] = useState(false);
    
    const [playbackData, setPlaybackData] = useState();

    const updateStream = data => {
  
      console.log(data);
      if (data) {

        if (data.action === 'video.live_stream.recording') {
          console.log('Stream will begin shortly');
          console.log(data.data);
          setPlaybackId(null);
         
        }
       
        if (data.action === 'video.live_stream.active') {
          console.log('Stream is Live');
          console.log(data.data);
          setCurrentStreamData(data.data);
          setIsLive(data.isStreaming);
          streamData && setPlaybackId(streamData.playbackId)
          
        }

        if (data.action === 'video.live_stream.idle') {
          console.log('Stream is not Live');
          console.log(data.data);
          setCurrentStreamData(data.data);
          setIsLive(data.isStreaming);
        }
  
      } 
      
    };

    useEffect(()=> {
      streamData && setPlaybackId(streamData.playbackId)
    },[streamData])
    

    // listen for new questions on WS
  useEffect(() => {
    socket.on('STREAM_STATUS', dataFromServer => {
      console.log(dataFromServer);
      updateStream(dataFromServer);
    });

    return () => {
      socket.off('STREAM_STATUS');
    }
  }, [socket]);


    // useEffect(() => {
    //   isLive && videoHandler('play');
    // }, [currentStreamData])
    

    // const videoHandler = (control) => {
    //   if (control === "play") {
       
          
    //   videoRef.current.play()
    //   .then(result => {
    //     console.log(result);
    //   })
    //   .catch(err => {
    //     console.log(err);
    //   })
      
    //   } else if (control === "pause") {
    //     videoRef.current.pause();
    //   }
    // };
    
    const progress = event => {
      console.log(event);
    }

    const waiting = event => {
      console.log(event);
      setIsBuffering(true);
    }

    const playing = event => {
      console.log(event);
      setIsBuffering(false);
    }
    const pause = event => {
      console.log(event);
    }
    
    const canPlayHandler = event => {
      console.log(event.target.metadata);
      setMediaReady(true);
      setIsBuffering(false);
    }

    const playerErrors = event => {
     console.log(event);
     setIsBuffering(false);
    }

    const updateMedia= event => {
      const totalDuration = event.target.duration;
      const currentTime = videoRef.current.currentTime;
      const currentValue = currentTime / totalDuration * 100

      if (mediaDuration !== totalDuration) {
        setMediaDuration(totalDuration);
      }
      setSeekerValue(currentValue)
    }

    return (
        <div id={styles.videoContainer}>

       {isBuffering && <LoadingSpinnerCenter message='buffering...' />}
      
       {streamData.playbackId && currentStreamData ? 
       <MuxVideo
            style={{ height: "100%", width: "100%",position: 'absolute', top: 0, left: 0, bottom:0, zIndex:4 }}
            ref={videoRef}
            playbackId={playbackId}
            poster={`${REACT_APP_API_URL}/${streamData.posterUri}`}
            metadata={{
              video_id: streamData.id,
              video_title: streamData.title,
              viewer_user_id: userId,
              video_variant_id: eventId,
              video_content_type: 'event',
            }}
            envKey={REACT_APP_MUX_DATA_KEY}
            streamType="live"
            controls
            autoPlay
            muted
            preload="auto"
            onPlaying={playing}
            onPause={pause}
            onProgress={progress}
            onCanPlay={canPlayHandler}
            onWaiting={waiting}
            onTimeUpdate={updateMedia}
            onError={playerErrors}
          />
          :
        <div  style={{ height: "100%", width: "100%",position: 'absolute', top: 0, left: 0 }}>
            <img  style={{ height: "100%", width: "100%" }} src={`${REACT_APP_API_URL}/${streamData.posterUri}`} alt='poster' />
        </div>
          }

          {/* <div className={styles.controls}>
          <div className={styles.header}> 
            {isLive &&  <LiveLabel />}
          </div>
          <LiveStreamControl 
            id={styles.videoContainer} 
            videoRef={videoRef} 
            mediaDuration={mediaDuration} 
            seekerValue={seekerValue} 
            currentData={currentStreamData} 
            isLive={isLive}
            mediaReady={mediaReady}
            mediaTitle = {streamData.title}
          />
        </div>
           */}
          </div>
        
      );
};

export default MuxPlayer;
