import React, { Fragment, Suspense } from 'react';

import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { SocketContext, socket } from './shared/context/socket';

import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  faSearch,
  faTrashAlt,
  faKey,
  faCheck,
  faCalendarAlt,
  faPoundSign,
  faSignInAlt,
  faSignOutAlt,
  faEnvelope,
  faExclamationTriangle,
  faPoll,
} from '@fortawesome/free-solid-svg-icons';

import Auth from './auth/pages/Auth';
import Event from './event/pages/Event';

import Header from './shared/components/Header/MainHeader';
import Footer from './shared/components/Footer/MainFooter';
import { useAuth } from './shared/hooks/auth-hook';
import { useEvent } from './shared/hooks/event-hook';
import { useLang } from './shared/hooks/lang-hook';
import { AuthContext } from './shared/context/auth-context';
import { EventContext } from './shared/context/event-context';
import { LangContext } from './shared/context/lang-context';
import LoadingSpinner from './shared/components/UIElements/Loading/LoadingSpinnerCenter';

import './App.css';
import Reset from './auth/pages/Reset';



library.add(
  faEdit,
  faChevronUp,
  faChevronDown,
  faVideo,
  faVideoSlash,
  faLock,
  faExclamationCircle,
  faPlusCircle,
  faCheckCircle,
  faTimesCircle,
  fab,
  faTrashAlt,
  faKey,
  faCheck,
  faSignOutAlt,
  faCalendarAlt,
  faSearch,
  faEnvelope,
  faPoundSign,
  faSignInAlt,
  faExclamationTriangle,
  faPoll
);

function App() {
  
  const { 
    userId, 
    email, 
    token, 
    lang, 
    login, 
    logout 
  } = useAuth();

  const {
    eventId, 
    title, 
    subTitle,
    uri, 
    date, 
    open, 
    supportEmail,
    createEvent, 
    clearEvent 
  } = useEvent();

  const {
    langCode,
    createLang 
  } = useLang();

  //const dispatch = useDispatch();

 
  if (!langCode) {
    const langLocal = localStorage.getItem('i18nextLng');
    if (lang ) {
      console.log('user defined');
      createLang(lang);
    } else if (langLocal) {
      console.log('langLocal defined');
      createLang(langLocal);
      // dispatch({ type: 'updateLanguage', lang: langCookie });
    } else {
      console.log('fallback defined');
      createLang('en');
    }
  } 
  

  let routes;

  token
    ? (routes = (
        <Fragment>
          <Header />
          <Routes>
            <Route path="/" exact element={ <Event />}/>
            <Route path="*" element={ <Event />}/>
          </Routes>
        </Fragment>
      ))
    : (routes = (
        <Routes>
          <Route path="/:eventExt/reset/:token" element={ <Reset />}/>
          <Route path="/:eventExt" element={ <Auth />}/>
          <Route path="*" element={ <Auth />}/>
        </Routes>
      ));

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: !!token,
        token: token,
        userId: userId,
        email: email,
        lang: lang,
        login: login,
        logout: logout,
      }}
    >
    <EventContext.Provider 
          value={{
            eventId: eventId,
            title: title,
            subTitle: subTitle,
            uri: uri, 
            date: date, 
            open: open, 
            supportEmail,
            createEvent: createEvent, 
            clearEvent: clearEvent,
        }}>
        <LangContext.Provider
          value={{
            langCode: langCode,
            createLang: createLang
          }}>
          
      <Router>
        <SocketContext.Provider value={socket}>
          <main>
            <Suspense fallback={<LoadingSpinner />}>
              {routes}
            </Suspense>
          </main>
          <Footer />
        </SocketContext.Provider>
      </Router>
      </LangContext.Provider>
      </EventContext.Provider>
    </AuthContext.Provider>
  );
}

export default App;
