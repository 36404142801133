import React from 'react';

import styles from './LangItem.module.css';
import Flag from '../UIElements/Flag';

const LangItem = ({region}) => {

    return (
        <div id={styles.langItem}>
             <Flag code={region.lang} /> <span className={styles.name}>{region.name}</span>
        </div>
    )
}

export default LangItem
