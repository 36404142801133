import React, {Fragment, useState, useEffect, useContext} from 'react';

import { EventContext } from '../../context/event-context';

import Branding from '../UIElements/Branding';
import TopLinks from './TopLinks';

import styles from './MainHeader.module.css'
import LangSelectSm from '../Language/LangSelectSm';

const MainHeader = props => {

  const [eventTitle, setEventTitle] = useState('');
  const [eventSubTitle, setEventSubTitle] = useState('');
  const eventCtx = useContext(EventContext);

  useEffect(() => {
    console.log('header rerender');
    setEventTitle(eventCtx.title);
    setEventSubTitle(eventCtx.subTitle);
  }, [eventCtx.title, eventCtx.subTitle]);

  return (
    <Fragment>
      <header className={styles.headerContainer}>
        <div className={styles.headerContainer__shell}>
          <div className={styles.headerContainer__top}> 
            <Branding
              src="./assets/images/logo-white.png"
              alt="Event Logo"
              eventTitle={eventTitle}
              eventSubTitle={eventSubTitle}
              width="120px"
              height="75px"
            /> 
           
            <TopLinks />
          </div>
        </div>
      </header>
    </Fragment>
  );
};

export default MainHeader;
