import React, {useEffect, useState, useContext} from 'react';
import { useTranslation } from 'react-i18next';

import { AuthContext } from '../../context/auth-context';
import { EventContext } from '../../context/event-context';
import Logo from '../UIElements/Logo';

import './MainFooter.css';

const MainFooter = () => {

    const { t } = useTranslation();
    const { lang } = useContext(AuthContext);
    const { supportEmail } = useContext(EventContext);

    const [footerLang, setFooterLang] = useState();

    useEffect(() => {
        setFooterLang(lang)
        //console.log(lang);
    }, [lang])

return (
    <footer>
    <div className="main-footer__shell">
        <div className="main-footer__text">
        <Logo/>
            {/* <p className="main-footer-text-p ft-title">{lang ? t('terms & conditions') : 'Terms & Conditions'}</p> */}
            {/* <p className="main-footer-text-p">Please note this Product Preview is exclusively for JCB dealer Principals so please do not share with the public until after our official launch in 2022.</p> */}
            <ul className="footer-links">
                <li>
                {supportEmail && <a href={`mailTo:${supportEmail}`}>{lang ? t('email support') : 'Email Support'}</a>}
                </li>
            </ul>
        </div>
       
        <div className="main-footer__bottom">
           {footerLang && <p> &copy; {t('copyright')} 2022 </p>}
           {!footerLang && <p> &copy; Copyright 2022 </p>}
        </div>

    </div>
    </footer>

)};

export default MainFooter;