import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Cookies from 'js-cookie';

import styles from './LangSelReg.module.css';

const REGIONS = [
    {
        id: 0,
        code:'en',
        title: 'English'
    },
    {
        id: 1,
        code:'de',
        title: 'Deutsch'
    },
    {
        id: 2,
        code:'fr',
        title: 'Français'
    },
    {
        id: 3,
        code:'es',
        title: 'Español'
    },
    {
        id: 4,
        code:'it',
        title: 'Italiano'
    }
]

const LangSelReg = ({selected}) => {

    const dispatch = useDispatch();

    const langCookie = Cookies.get('i18next')
    if (langCookie !== 'en') {
        dispatch({ type: 'updateLanguage', lang: langCookie})
    }


    const {t, i18n} = useTranslation();
    const changeLanguage = (language) => {
        setLang(language);
        i18n.changeLanguage(language);
    }

    const setLang = (language) => {
        dispatch({ type: 'updateLanguage', lang: language});
        selected(language);
    }

    return (
        <div id={`${styles.LangSelReg}`}>
        <p className={`${styles.instruction}`}>Choose Your Region:</p>
    
        {REGIONS && REGIONS.map(region => (
            <div key={region.id} className={styles.itemWrapper} onClick={()=>changeLanguage(region.code)}>
                <div className={styles.iconWrapper}> <FontAwesomeIcon icon={faGlobe} /></div>
                <div className={styles.titleWrapper}><h2 className={styles.title}>{region.title}</h2></div>
            </div>
        ))}
        </div>
    );
};

export default LangSelReg;
