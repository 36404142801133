import React, { Fragment, useState, useEffect, useContext } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { faPoll, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SocketContext } from '../../context/socket';

import { useHttpClient } from '../../hooks/http-hook';
import { AuthContext } from '../../context/auth-context';
import { useTranslation } from 'react-i18next';

import ErrorModal from '../UIElements/Modal/ErrorModal';
import LoadingSpinner from '../UIElements/LoadingSpinner';

import styles from './Polls.module.css';
import NoSessionsNotice from './NoSessionsNotice';

import PollList from './PollList';
import PollItem from './PollItem';
import PollVote from './/PollVote';


const variants = {
  open: { opacity: 1, right: 0 },
  closed: { width:'0', opacity: 0, right: -500 },
};

const Polls = ({ eventId, showClose, closed }) => {

  const { REACT_APP_API_URL } = process.env;
  const { isLoading, sendRequest, error, clearError } = useHttpClient();

  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [sessionState, setSessionState] = useState(false);
  const [selectedPoll, setSelectedPoll] = useState();
  const [castNewVote, setCastNewVote] = useState();
  const [polls, setPolls] = useState([]);
  const [showList, setShowList] = useState(true);
  const [pollTitle, setPollTitle] = useState('Select a Poll');
  const [showChart, setShowChart] = useState(false);
  const [update, setUpdate] = useState(false);
  const [newPoll, setNewPoll] = useState();
  const [pollToRemove, setPollToRemove] = useState();
  const [pushedPoll, setPushedPoll] = useState();
  const [pollToReset, setPollToReset] = useState();

  const updatePolls = data => {
    
    const pid = getCurrentPollId();

    console.log(data);
    if (data) {
      if (data.vote) {
        if (data.vote.pollId === pid) {
          switch (data.action) {
            case 'new':
              setCastNewVote(data.vote);
              break;

            default:
              break;
          }
        }
      }
      if (data.action === 'toggle') {
        console.log('toggle');
        
        if (data.poll.id === pid) {
          console.log('POLL TOGGLED');
          toggleSessionState(data.open);
        } 

        if (data.open) {
         setNewPoll(data.poll);
        } else {
          setPollToRemove(data.poll);
        }
      }
      if (data.action === 'show') {
        console.log('Show Chart');
        
        if (data.poll.id === pid) {
          setShowChart(true);
        } 
      }
      if (data.action === 'hide') {
        console.log('Hide Chart');
        
        if (data.poll.id === pid) {
          setShowChart(false);
        } 
      }
      if (data.action === 'push') {
        console.log('poll pushed');
        setPushedPoll(data.poll);
      }
      if (data.action === 'chart reset') {
        console.log('poll reset');
        setPollToReset(data.poll);
      }
    } 
    
  };
  
  const resetView = () => {
    setSelectedPoll(null);
    setPollTitle('Select a Poll');
  };

  useEffect(()=>{
    addPoll(newPoll);
  },[newPoll]);

  useEffect(()=>{
    removePoll(newPoll);
  },[pollToRemove]);
 

  useEffect(() => {
      if (closed === true) {
        setShowList(true);  
    }
  }, [closed]);

  useEffect(() => {
    if (pushedPoll) {
      pushPoll(pushedPoll);
    }
  }, [pushedPoll]);

  useEffect(() => {

    const fetchPolls = async () => {
      try {
        const responseData = await sendRequest(
          `${REACT_APP_API_URL}/api/poll/event-live-polls/${eventId}`,
          'POST',
          {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + auth.token,
          }
        );
        console.log(responseData);
        if (responseData.polls.length > 0) {
          setPolls(() => [...responseData.polls]);
          if (responseData.polls.length === 1) {
            selectedSessionHandler(responseData.polls[0]);
          }
        } else {
          setPolls(() => []);
          setPollTitle('Polling Closed')
        }
      } catch (err) {
        console.log(err);
      }
    };
    fetchPolls();
  }, [sessionState, update]);

  const selectedSessionHandler = async poll => {
    setPollTitle(poll.question);

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/poll/get/${poll.id}`,
        'POST',
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + auth.token,
        }
      );
      console.log(responseData.poll);
      if (responseData.poll) {
        setSelectedPoll(responseData.poll);
        setSessionState(responseData.poll.open);
        setShowChart(responseData.poll.showPollChart);
        localStorage.setItem(
          'sessionData',
          JSON.stringify({ psid: responseData.poll.id })
        );
        
      } 
    } catch (err) {
      console.log(err);
    }
  };

  // listen for new questions on WS
  useEffect(() => {
    socket.on('POLLING', dataFromServer => {
      updatePolls(dataFromServer);
    });

    return () => {
      socket.off('POLLING');
    }
  }, [socket]);

  const toggleSessionState = isLive => {
    setSessionState(isLive);
  };

  const getCurrentPollId = () => {
    const data = localStorage.getItem('sessionData');

    if (data) {
      return JSON.parse(data).psid;
    }
  };

  const addPoll = poll => {
    console.log('Add Poll');
    if(polls.length > 0) {
      setPolls(polls => [...polls, poll]);
    } else {
      setUpdate(update ? false : true);
    }
    
  }


  const removePoll = poll => {
    if (poll) {
      console.log('Remove Poll');
      let pollArr = polls.filter( el => el.id !== poll.id ); 
      setPolls(()=> [...pollArr]);
    } else {
      resetView();
    }
   
  }

  useEffect(() => {
    pollToReset && resetPoll(pollToReset);
  }, [pollToReset])

  const resetPoll = (pollData) => {

    setSelectedPoll(pollData)
    
  }

 
  const pushPoll = poll => {
    console.log(poll);
    setIsOpen(true);
    setSelectedPoll(poll);
    setSessionState(poll.open);
    setShowChart(poll.showPollChart);
    closeFullScreenView();
    localStorage.setItem(
      'sessionData',
      JSON.stringify({ psid: poll.id })
    );
    setPollTitle(poll.question);
  }

  const closeFullScreenView = () => {

    if (!window.screenTop && !window.screenY) {
      if(document.exitFullscreen) {
        document.exitFullscreen();
      } else if(document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if(document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  return (
    <Fragment>
      <ErrorModal error={error} onClear={clearError} />
      {polls.length > 0 && <div id={styles.pollWrapper} onClick={() => setIsOpen(isOpen => !isOpen)}>
        <FontAwesomeIcon icon={faPoll} />
        <span>{t('polls.polls')}</span>
      </div>}

      <AnimatePresence mode='wait'>
        <motion.div
          key="drawer"
          className={styles.pollDrawWrapper}
          animate={isOpen ? 'open' : 'closed'}
          variants={variants}
        >
          <div className={styles.titleWrapper}>
            <h1 className={styles.title}>{pollTitle}</h1>
          </div>

          <div className={styles.pollBodyWrapper}>

            {polls.length === 0 && (
              <NoSessionsNotice
                title={t('closed')}
                noticeText={'All polls are currently closed.'}
              />
            )}
            {selectedPoll && !isLoading && (
              <div>
             {showChart && sessionState && <PollItem poll={selectedPoll} selected={selectedSessionHandler}/>}
              {sessionState &&  <PollVote poll={selectedPoll} isOpen={sessionState}/>}
              </div>
            )}
            {isLoading && (
              <div className={styles.centerSpinner}>
                <div className={`center ${styles.loadingSpinner}`}>
                  <LoadingSpinner />
                  <p className={styles.loadingText}>{t('please wait')}...</p>
                </div>
              </div>
            )}
            {!selectedPoll && !isLoading && (
              <PollList polls={polls} selected={selectedSessionHandler}/>
            )}
          </div>

          <div className={styles.editorWrapper}>
        
            <div className={styles.closeWrapper}>
                <div className={styles.closeBtn} onClick={() => setIsOpen(isOpen => !isOpen)}>
                  <span className={styles.closeIcon}><FontAwesomeIcon icon={faTimes} /></span>
                  <p  className={styles.closeText}>{t('close')}</p>
                </div>

                {polls.length > 1 && <div className={styles.sessionBtn} onClick={resetView}>
                  <span className={styles.sessionIcon}><FontAwesomeIcon icon={faPoll} /></span>
                  <p  className={styles.sessionText}>{t('polls.polls')}</p>
                </div>}
            </div>
          </div>
        </motion.div>
      </AnimatePresence>
    </Fragment>
  );
};

export default Polls;
