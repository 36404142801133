import React from 'react';

import styles from './LoadingSpinnerCenter.module.css';

const LoadingSpinnerCenter = props => {
  return (
    <div className={`${styles.loading} ${props.classNameWrapper}`}>
      <div className={styles.loadingContiner}>
        <span className={styles.loader}></span>
        <p className={styles.loadingMessage}>{`${
          props.message || 'Please wait...'
        }`}</p>
      </div>{' '}
    </div>
  );
};

export default LoadingSpinnerCenter;

