import React from 'react';

import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import styles from './LoginForm.module.css';

const NotFound = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: 200,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: -200,
    },
  };

  const { t } = useTranslation();

  return (
    <motion.div
      key={'not-found'}
      className={styles.formWrapper}
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
    >
      <h1 className={styles.formTitle}>{t('welcome')}</h1>
      <p className={styles.countDownTitle}>{t('welcome title')}</p>

      <p className={styles.countdownSubText}>
        {t('Please follow the link in your email to be directed to the correct event.')}
      </p>
      <p className={styles.countdownSubTextLight}>
        {t("If you are looking to register for an event, Please follow the link in your email to be directed to the correct event.")}
      </p>
    </motion.div>
  );
};

export default NotFound;
