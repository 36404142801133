import React from 'react';
import moment from 'moment';
import { motion } from 'framer-motion';

import styles from './QAAnswer.module.css';

const QAAnswer = ({answers}) => {

    return (
        <motion.div 
            key={answers.id}
            initial={{ opacity: 0, y: -100 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -100 }}
            className={styles.aContainer}
        >
            <div className={styles.aBody}>
                {answers.answer}
            </div>
            <div className={styles.aInfo}>
                <div className={styles.aAuthor}>
                    <p className={styles.answerInfoText}>Admin</p>
                </div>
                <div className={styles.aTimestamp}>
                    <p className={styles.answerInfoText}>{moment(answers.createdAt).format('LT')}</p>
                </div>
            </div>
    </motion.div>
    )
}
export default QAAnswer;