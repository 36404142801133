import React from 'react';

import './Branding.css';

const Branding = props => {

    return (
        <div className={`branding ${props.className}`} style={props.style}>
        <div className={'branding_inner_wrapper'}>
          <img
            src={props.src}
            alt={props.alt}
            style={{ height: props.height, width: props.width}}
            className='branding_header_logo'
          />
          <div className={'branding_inner_title'}>
            <h1>{props.eventTitle}</h1>
            <h2>{props.eventSubTitle}</h2>
          </div>
          
        </div>
        
      </div>
    );
};


export default Branding;