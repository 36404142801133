import React from 'react';

import styles from './VersionNumber.module.css';

const VersionNumber = () => {
    return (
        <div id={styles.vnw}>
            <span>v2.0.5</span>
        </div>
    )
}


export default VersionNumber
