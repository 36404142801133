import React from 'react';
import QASessionItem from './QASessionItem';
import { useTranslation } from 'react-i18next';

import styles from './QASessionList.module.css'


const QASessionList = ({sessions, selectedSession}) => {
    
    const {t} = useTranslation();
    

    return (
        <section id={styles.sessionListWrapper}>
            <div className={styles.sessionListContainer}>
            <h1>{t('qa.please select a q&a session')}</h1>
                {sessions && sessions.map(session => (
                    session.live && <QASessionItem key={session.id} session={session} selectedSession={selectedSession} />
                ))}
            </div>
        </section>
    )
}

export default QASessionList;