import React, { Fragment } from 'react';

import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import styles from './LoginForm.module.css';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';

const LoadingEvent = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: 200,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: -200,
    },
  };

  const { t } = useTranslation();

  return (
    <Fragment>
      <motion.div
        key={'loading'}
        className={styles.formWrapper}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <h1 className={styles.formTitle}>{t('welcome')}</h1>
        <h1 className={styles.countDownTitle}>
          Welcome to the JCB Event space!
        </h1>
        <div className='center'>
          <LoadingSpinner />
        </div>

       
      </motion.div>
    </Fragment>
  );
};

export default LoadingEvent;
