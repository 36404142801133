import React, { Fragment, useContext, useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Bowser from 'bowser';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import { useHttpClient } from '../../shared/hooks/http-hook';
import { AuthContext } from '../../shared/context/auth-context';
import { SocketContext } from '../../shared/context/socket';
import { useEventStatusCheck } from '../../shared/hooks/event-status-hook';

import CountDown from '../../shared/components/UIElements/Countdown/CountDown';
import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';
import CountDownComplete from './CountDownComplete';

import styles from './LoginForm.module.css';

const LoginForm = ({ eventId, updateRegions }) => {
  const { eventExt } = useParams();

  console.log(eventExt);

  const pageVariants = {
    initial: {
      opacity: 0,
      x: 200,
    },
    in: {
      opacity: 1,
      x: 0,
    },
    out: {
      opacity: 0,
      x: -200,
    },
  };

  const { REACT_APP_API_URL } = process.env;
  const { t } = useTranslation();
  const auth = useContext(AuthContext);
  const socket = useContext(SocketContext);
  const navigate = useNavigate();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { eventData, loading } = useEventStatusCheck();
  const agentStats = Bowser.parse(window.navigator.userAgent);

  const [errors, setErrors] = useState();
  const [emailError, setEmailError] = useState(false);
  const [passError, setPassError] = useState(false);
  const [eventIsOpen, setEventIsOpen] = useState(false);
  const [eventDate, setEventDate] = useState();
  const [showStaffLogin, setShowStaffLogin] = useState(false);
  const [countDownComplete, setCountDownComplete] = useState(false);

  useEffect(() => {
    console.log(eventData);
    eventData && setEventIsOpen(eventData.open);
    eventData && setEventDate(eventData.date);
    if (eventData) {
      if (eventData.regions) {
        updateRegions()
      }
    }
  }, [eventData]);

  useEffect(() => {
    let timer = setTimeout(() => {
        setShowStaffLogin(false);
      }, 30000);

      return () => {
        clearTimeout(timer);
      };
    
  }, []);

  const submitHandler = async event => {
    event.preventDefault();

    const email = event.target.email.value || null;
    const password = event.target.password.value || null;

    resetErrors();

    if (email === null) {
      setErrors({ email: { type: 'required' } });
      setEmailError(true);
    } else if (password === null) {
      emailError && setEmailError(() => false);
      setErrors({ password: { type: 'required' } });
      setPassError(true);
    } else {
      const browserName = agentStats.browser.name;
      const browserVerion = agentStats.browser.version;
      const osName = agentStats.os.name;
      const osVersion = agentStats.os.version;
      const osVersionName = agentStats.os.versionName;
      const platform = agentStats.platform.type;

      try {
        const response = await sendRequest(
          `${REACT_APP_API_URL}/api/auth/`,
          'POST',
          JSON.stringify({
            email: email,
            password: password,
            browserName: browserName,
            browserVerion: browserVerion,
            osName: osName,
            osVersion: osVersion,
            osVersionName: osVersionName,
            platform: platform,
            eventIsOpen: eventIsOpen,
            eventId: eventId,
          }),
          {
            'Content-Type': 'application/json',
          }
        );

        let userId;
        if (response.userId) {
          userId = response.userId;
          socket.auth = {
            userId,
            email: email,
            evid: eventId,
          };
          socket.connect();

          auth.login(
            userId,
            response.email,
            response.lang,
            response.token
          );
          navigate('/');
        }

        
      } catch (err) {
        console.log(err);
      }
    }
  };

  const navLoginHandler = () => {
    navigate(`/${eventExt}/register`);
  };

  const resetErrors = () => {
    setErrors();
    setEmailError(false);
    setPassError(false);
  };

  const countDownCompleteHandler = () => {
    setCountDownComplete(true);
  };

  return (
    <>
      <ErrorModal error={error} onClear={clearError} />
      <motion.div
        key={'loginForm'}
        className={styles.formWrapper}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageVariants}
      >
        <h1 className={styles.formTitle}>{t('welcome')}</h1>
        {isLoading && (
          <div className="center">
            <LoadingSpinner classNameWrapper={styles.loadingWrapper}/>
          </div>
        )}

        {!isLoading && eventIsOpen && (
          <form className={styles.form} onSubmit={submitHandler}>
            {/* <h2 className={styles.loginNotice}>
              Please note this Product Preview is exclusively for JCB dealer
              Principals so please do not share with the public until after our
              official launch in 2022.
            </h2>  */}
              <h2 className={styles.loginNotice}>
              Private & confidential information please no filming or sharing any of the content this morning.
            </h2>  
            <div className={styles.inputWrapper}>
              <label>
                {t(`email`)}:
                <span
                  className={
                    emailError ? styles.errorInputLabel : styles.noError
                  }
                >
                  {errors &&
                    errors.email &&
                    errors.email.type === 'required' &&
                    t(`email is required`) + '!'}
                </span>
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder={t(`email`)}
                inputClassName={`${styles.input} ${
                  emailError ? styles.errorInput : styles.noError
                }`}
              />
              <label>
                {t(`password`)}:
                <span
                  className={
                    passError ? styles.errorInputLabel : styles.noError
                  }
                >
                  {errors &&
                    errors.password &&
                    errors.password.type === 'required' &&
                    t(`password is required`) + '!'}
                  {errors &&
                    errors.password &&
                    errors.password.type === 'min char' &&
                    t(`password must contain at least 5 characters`) + '!'}
                </span>
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder={t(`password`)}
                inputClassName={`${styles.input} ${
                  passError ? styles.errorInput : styles.noError
                }`}
              />
            </div>
            <div className={styles.actions}>
              <Button
                type="submit"
                className={`button-secondary ${styles.enterBtn}`}
                title={t(`enter`)}
              />
              <Button
                type="button"
                title={t(`register`)}
                onClick={navLoginHandler}
              />
            </div>
            <Link to={`/${eventExt}/forgot`}>{t('forgot password')}</Link>
          </form>
        )}

        {!isLoading && showStaffLogin && !eventIsOpen && (
          <form className={styles.form} onSubmit={submitHandler}>
            <h2 className={styles.countdownSubText}>
              Registered staff members only.
            </h2>
            <div className={styles.inputWrapper}>
              <label>
                {t(`email`)}:
                <span
                  className={
                    emailError ? styles.errorInputLabel : styles.noError
                  }
                >
                  {errors &&
                    errors.email &&
                    errors.email.type === 'required' &&
                    t(`email is required`) + '!'}
                </span>
              </label>
              <Input
                id="email"
                name="email"
                type="email"
                placeholder={t(`email`)}
                inputClassName={`${styles.input} ${
                  emailError ? styles.errorInput : styles.noError
                }`}
              />
              <label>
                {t(`password`)}:
                <span
                  className={
                    passError ? styles.errorInputLabel : styles.noError
                  }
                >
                  {errors &&
                    errors.password &&
                    errors.password.type === 'required' &&
                    t(`password is required`) + '!'}
                  {errors &&
                    errors.password &&
                    errors.password.type === 'min char' &&
                    t(`password must contain at least 5 characters`) + '!'}
                </span>
              </label>
              <Input
                id="password"
                name="password"
                type="password"
                placeholder={t(`password`)}
                inputClassName={`${styles.input} ${
                  passError ? styles.errorInput : styles.noError
                }`}
              />
            </div>
            <div className={styles.actions}>
              <Button
                type="submit"
                className={`button-primary ${styles.enterBtn}`}
                title={t(`enter`)}
              />
              <Button
                type="button"
                className={`button-white ${styles.registerBtn}`}
                title={t(`register`)}
                onClick={navLoginHandler}
              />
            </div>
            <Link to={`/${eventExt}/forgot`}>{t('forgot password?')}</Link>
          </form>
        )}

        {!isLoading && !eventIsOpen && !showStaffLogin && (
          <div className={styles.form} onSubmit={submitHandler}>
            {countDownComplete ? (
              <CountDownComplete eventDate={eventDate} />
            ) : (
              <div className={styles.inputWrapper}>
                <h1 className={styles.countDownTitle}>
                  The Countdown to our next event has started!
                </h1>
                <h2 className={styles.countdownSubText}>Event will open in:</h2>
                {eventDate && (
                  <CountDown
                    eventDate={eventDate}
                    complete={countDownCompleteHandler}
                  />
                )}
                <h2 className={styles.loginNotice}>
                  Login will appear when the event is open!.
                </h2>
              </div>
            )}
            <div className={styles.clockActions}>
              <Button
                type="button"
                title={t(`register`)}
                onClick={navLoginHandler}
              />
            </div>
            <button
              className={styles.textLink}
              type="button"
              onClick={() => setShowStaffLogin(true)}
            >
              Staff Login
            </button>
          </div>
        )}
      </motion.div>
    </>
  );
};

export default LoginForm;
