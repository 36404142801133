import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import { useHttpClient } from '../../shared/hooks/http-hook';
import ResetPassword from '../components/ResetPassword';
import NotFound from '../components/NotFound';

import styles from './Auth.module.css';
import LoadingEvent from '../components/LoadingEvent';
import VersionNumber from '../../shared/components/versionNumber/VersionNumber';
import LangSelSm from '../../shared/components/Language/LangSelectSm';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';

const Reset = () => {
  const pageVariants = {
    initial: {
      opacity: 0,
      x: -100,
      y: -50
    },
    in: {
      opacity: 1,
      x: 0,
      y: -50,
      transition: {
        delay: .5,
      },
    },
    out: {
      opacity: 0,
      x: -100,
      y: -50
    },
  };

  const backgroundData = [
    {
      img: 'coff01.jpg',
      position: 'center right',
    },
    {
      img: 'coff01.jpg',
      position: 'center center',
    },
    {
      img: 'coff01.jpg',
      position: 'center center',
    }
  ];

  const { REACT_APP_API_URL } = process.env;
  const { eventExt } = useParams();
  const [eventFound, setEventFound] = useState();
  const [updateRegions, setUpdateRegions] = useState(false);
  const [eventData, setEventData] = useState();
  const { isLoading, sendRequest, error, clearError } = useHttpClient();
  const random = Math.floor(Math.random() * backgroundData.length);
  const [background, setBackground] = useState({
    img: 'coff01.jpg',
    position: 'center center',
  });

  const getEvent = async uri => {
    try {
      const response = await sendRequest(
        `${REACT_APP_API_URL}/api/event/get-id`,
        'POST',
        JSON.stringify({
          uri,
        }),
        {
          'Content-Type': 'application/json',
        }
      );

      console.log(response);
      if (response) {
        if (response.event) {
          if (response.event.eventId) {
            console.log(response.event);
            setEventData(response.event);
            setEventFound(true);
          }
        }
      } else {
        setEventFound(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setBackground(backgroundData[random]);
    eventExt && getEvent(eventExt);
    console.log(eventExt);
  }, [eventExt]);

  return (
    <>
    {/* // <AnimatePresence mode='wait'> */}
      <div id={styles.wrapper} style={{
            backgroundImage: `url("/assets/images/${background.img}")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: background.position,
            backgroundSize: 'cover',
          }}>
      <ErrorModal error={error} onClear={clearError} />
      <div className={styles.authLangWrapper}>
    <LangSelSm menuClassName={styles.langSel} updateRegions={updateRegions} />
      </div>
        <div id={styles.leftContiner}>
        <AnimatePresence mode='wait'>
          <motion.img
            key={'Main Branding'}
            initial="initial"
            animate="in"
            exit="out"
            variants={pageVariants}
            className={styles.branding}
            src="/assets/images/logo.png"
            alt="Event Logo"
          />
          </AnimatePresence>
        </div>
        <div id={styles.rightContainer} >
         <AnimatePresence mode='wait'>
            <ResetPassword />
          </AnimatePresence>
          {isLoading && <LoadingEvent />}
          {!isLoading && !eventFound && <NotFound />}
        </div>
      </div>
      <VersionNumber/>
    {/* // </AnimatePresence> */}
    </>
  );
};

export default Reset;
