import React from 'react';

import styles from './QASessionItem.module.css';

const QASessionItem = ({session, selectedSession}) => {

    return (
        <div className={styles.itemWrapper} onClick={()=>selectedSession(session)}>
            <h1>{session.name}</h1>
        </div>
    )
}

export default QASessionItem;