import React, { useState, useEffect, Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './CountDown.module.css';

const CountDown = ({ eventDate, complete }) => {
  const [days, setDays] = useState('--');
  const [hours, setHours] = useState('--');
  const [mins, setMins] = useState('--');
  const [secs, setSecs] = useState('--');
  const { t } = useTranslation();

  useEffect(() => {
    console.log(eventDate);
    let coutdown = setInterval(() => getTimeUntil(eventDate), 1000);
      return () => {
        clearTimeout(coutdown);
      }
  }, [eventDate])

  const getTimeUntil = showTime => {
    const time = Date.parse(showTime) - Date.parse(new Date());
    if (time < 0) {
      setDays('00');
      setHours('00');
      setMins('00');
      setSecs('00');
      complete();
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      
      setDays(days);
      setHours(hours);
      setMins(minutes);
      setSecs(seconds);
    }
  };

  return (
    
    <div id={styles.countdownWrapper}>
      <div className={styles.days}>
        <h1 className={styles.counter}>{days <= 9 ? `0${days}` : days}</h1>
        <p className={styles.counterLabel}>{t('countdown.days')}</p>
      </div>
      <span>:</span>
      <div className={styles.hours}>
        <h1 className={styles.counter}>{hours <= 9 ? `0${hours}` : hours}</h1>
        <p className={styles.counterLabel}>{t('countdown.hours')}</p>
      </div>
      <span>:</span>
      <div className={styles.mins}>
        <h1 className={styles.counter}>{mins <= 9 ? `0${mins}` : mins}</h1>
        <p className={styles.counterLabel}>{t('countdown.mins')}</p>
      </div>
      <span>:</span>
      <div className={styles.secs}>
        <h1 className={styles.counter}>{secs <= 9 ? `0${secs}` : secs}</h1>
        <p className={styles.counterLabel}>{t('countdown.secs')}</p>
      </div>
    </div>
    
  );
};

export default CountDown;
