import React, { Fragment, useState }  from 'react';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import Input from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/UIElements/Buttons/Button';
import ErrorModal from '../../shared/components/UIElements/Modal/ErrorModal';
import LoadingSpinner from '../../shared/components/UIElements/Loading/LoadingSpinnerCenter';
import { useHttpClient } from '../../shared/hooks/http-hook';

import styles from './RegisterForm.module.css';
import LangSelReg from '../../shared/components/Language/LangSelReg';
import BackButton from './BackButton';

const RegisterForm = ({eventId}) => {

    const pageVariants = {
        initial: {
          opacity: 0,
          x: 200,
        },
        in: {
          opacity: 1,
          x: 0,
          
        },
        out: {
          opacity: 0,
          x: -200,
        },
    }

    const { REACT_APP_API_URL } = process.env;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const [errors, setErrors] = useState();
    const [registeredData, setRregisteredData] = useState();
    const [submitted, setSubmitted] = useState(false);
    const [region, setRegion] = useState('en');
    const [emailError, setEmailError] = useState(false);
    const [passError, setPassError] = useState(false);
    const [pass2Error, setPass2Error] = useState(false);

    const onSubmit = async data => {
        data.preventDefault();
        
        const email = data.target.email.value || null;
        const pass = data.target.pass.value || null;
        const pass2 = data.target.pass2.value || null;
        const name = data.target.name.value || 'Unknown';
        const organisation = data.target.organisation.value || 'Not Given';
        
        resetErrors();

        if (email === null) {
          setErrors({ email: { type: 'required' } });
          setEmailError(true)
        } 

        else if (pass === null) {
          emailError && setEmailError(()=>false);
          setErrors({ pass: { type: 'required' } });
          setPassError(true);
        } 

        else if (pass.length < 5) {
          setErrors({ pass: { type: 'min char' } });
          setPassError(true);
        }

        else if (pass2 === null) {
          passError && setPassError(()=>false)
          setErrors({ pass2: { type: 'required' } });
          setPass2Error(true);
        } 

        else if (pass !== pass2) {
          setErrors({ pass2: { type: 'no match' } });
          setPass2Error(true);
        } 
        
        else {
          resetErrors();
          //console.log(email);
          //console.log(name);
          //console.log(pass);
          //console.log(organisation);
    
          try {
            const response = await sendRequest(
              `${REACT_APP_API_URL}/api/user/register-user`,
              'POST',
                JSON.stringify({
                  email: email,
                  name: name,
                  password: pass,
                  region: region,
                  organization: organisation,
                  eventId
                }),
                {
                  'Content-Type': 'application/json'
                }
              );
              //console.log(response);
                setRregisteredData(response)
                setSubmitted(true);
              // if sucess load a sucess page tell them to await approval
          }
          catch (err) {
            //console.log(err);
          }
        }
      };

    const resetErrors = () => {
      setErrors();
      setEmailError(false);
      setPassError(false);
      setPass2Error(false);
    }

    const ticketOnChangeHandler = e => {
        //console.log(e.target.value);
      }
    
      const navLoginHandler = () => {
        navigate("/");
      };

      const regionSelection = lang => {
        setRegion(lang);
        //console.log(lang);
      }

    return (
        <>
        <ErrorModal error={error} onClear={clearError}/>
        <motion.div 
              key={'register'}
              className={styles.formWrapper}
              initial="initial"
              animate="in"
              exit="out"
              variants={pageVariants}
            > 
           
              <h1 className={styles.formTitle}>{submitted ? t("success") : t("register")}</h1>

              {isLoading && <div className='center'><LoadingSpinner /></div>}

              {!submitted && !isLoading && 
              <form className={styles.form} onSubmit={onSubmit}>
                {!region ? 
                  <div>
                    <LangSelReg selected={regionSelection} />
                    <BackButton />
                  </div>
                  :
                  <motion.div
                    key={'RegsterFields'}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                  >
                    <div className={styles.inputWrapper}>
                        <label>{t(`name`)}:</label>
                        <Input id="name" name="name" type="name" placeholder={t(`optional`)} inputClassName={`${styles.input}`}/>
                        
                        <label>
                        {t(`email`)}:
                            <span className={emailError ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.email &&
                                errors.email.type === 'required' &&
                                t(`email is required`) + '!'}
                            </span>
                        </label>
                        <Input id="email" name="email" type="email" placeholder={t(`required`)} inputClassName={`${styles.input} ${emailError ? styles.errorInput : styles.noError}`}/>

                        <label>{t(`organisation`)}:</label>
                        <Input id="organisation" name="organisation" type="company" placeholder={t(`optional`)}  onChange={ticketOnChangeHandler} inputClassName={`${styles.input}`}/>

                        <label>{t(`new password`)}:
                            <span className={passError ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.pass &&
                                errors.pass.type === 'required' &&
                                t(`password is required`) + '!'}
                                {errors &&
                                errors.pass &&
                                errors.pass.type === 'min char' &&
                                t(`password must contain at least 5 characters`) + '!'}
                            </span>
                        </label>
                        <Input id="pass" name="pass" type="password" placeholder={t(`required`)} autoComplete="new-password" onChange={ticketOnChangeHandler} inputClassName={`${styles.input} ${passError ? styles.errorInput : styles.noError}`}/>
                  
                        <label>{t(`confirm password`)}:
                        <span className={pass2Error ? styles.errorInputLabel : styles.noError}>
                                {errors &&
                                errors.pass2 &&
                                errors.pass2.type === 'required' &&
                                t(`password confirmation is required`) + '!'}
                                {errors &&
                                errors.pass2 &&
                                errors.pass2.type === 'no match' &&
                                t(`passwords do not match`) + '!'}
                            </span></label>
                        <Input id="pass2" name="pass2" type="password" placeholder={t(`required`)} autoComplete="new-password" onChange={ticketOnChangeHandler} inputClassName={`${styles.input} ${pass2Error ? styles.errorInput : styles.noError}`}/>
                    </div>
                    <div className={styles.actions}>
                      <Button  type="submit" className={`button-primary ${styles.enterBtn}`}  title={t(`submit`)}/>
                    </div>

                    <BackButton />

                  </motion.div>
                }
              </form>}
              {submitted && 
                <motion.div 
                    key={'Regstered'}
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants} 
                    className={styles.submittedContiner}
                >
                    <h2 className={styles.submittedTitle}>{t('thank you for registering title')}</h2>
                    <h3 className={styles.submittedEmail}>{registeredData.user.email}</h3>
                    <p className={styles.submittedText}> {t('thank you for registering text')}</p>
                    <p className={styles.submittedText}>{t('We look forward to welcoming you to our event')}</p>

                    <Button className={styles.registeredBtn} title={t('register another email')} onClick={()=>setSubmitted(false)} />
                </motion.div>
              }
        </motion.div>

    </>
    )
}

export default RegisterForm;