import React from 'react';

import PollListItem from './PollListItem';
import styles from './PollList.module.css';

const PollList = ({polls, selected}) => {

  return (
    
    <div>
      {polls && polls.map((poll, idx )=> (
        <ul
          key={idx}
          className={styles.pollList}
          initial={{ x:  -100, opacity: 0 }} 
          animate={{ x:  0, opacity: 1 }} 
          exit={{ x:  500, opacity: 0 }} 
          transition={{ duration: 1 }} 
        >
          <PollListItem key={poll.id} selected={selected} poll={poll} /> 
        </ul>
      ))}
      
    </div>
      
  )
}

export default PollList;