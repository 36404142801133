import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState} from 'react'

import styles from './AgendaList.module.css';


const AgendaList = ({agenda}) => {

    const [isAgenda, setIsAgenda] = useState(false);
    const { REACT_APP_API_URL } = process.env;

    useEffect(() => {
        agenda && agenda.length > 0 && setIsAgenda(true);
    }, [agenda])


    return (
        <div className={styles.ListWrapper}>
            {isAgenda && agenda.map(item => (
                <div key={item.id} className={styles.agendaItemWrapper}>
                <div className={styles.itemHeader}>
                    <div className={styles.imageWrapper}>
                        <div className={styles.image}>
                            <img className={styles.image} src={`${REACT_APP_API_URL}/${item.profileImageUrl}`} alt={'agenda'}/>
                        </div>
                    </div>
                    <div className={styles.headerInfoWrapper}>
                        <h1 className={styles.title}>{item.title}</h1>
                        <h2 className={styles.subtitle}>{item.subTitle}</h2>
                    </div>
                </div>
                    <div className={styles.timesWrapper}>
                        <FontAwesomeIcon icon={faClock} />
                        <p className={styles.times}>start: <span>{item.startTime}</span></p>
                        <p className={styles.times}>end: <span>{item.endTime}</span></p>
                    </div>
                    <div className={styles.description}>
                        <p className={styles.desc}>{item.shortDesc}</p>
                    </div>
                </div>
            ))}
            
        </div>
    )
}

export default AgendaList
