import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import './index.css';
import App from './App';
import store from './store/index';
import './utilities/i18n';
import LoadingSpinner from './shared/components/UIElements/Loading/LoadingSpinnerCenter';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
  <Suspense fallback={<div className='center'><LoadingSpinner /></div>}>
    <App />
    </Suspense>
</Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

