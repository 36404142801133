import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Cookies from 'js-cookie';

export const useLang = () => {

  const [langCode, setLangCode] = useState(null);
  const { i18n } = useTranslation();
  
  const createLang = useCallback((langCode) => {
    setLangCode(()=>langCode);

    console.log('setting lang Context');
    i18n.changeLanguage(langCode);
    localStorage.setItem(
        'i18nextLng',
        langCode
      );
    },
    []
  );

  const clearLang = useCallback(() => {
    setLangCode(null);
    i18n.changeLanguage('en');
    localStorage.removeItem('i18nextLng');
  }, []);

  // useEffect(() => {
  //   const storedData = JSON.parse(localStorage.getItem('langCode'));
  //   storedData && createLang(
  //   storedData.langCode
  //   );
    
  // }, [createLang]);

  return { langCode,  createLang, clearLang };
};
