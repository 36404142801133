import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPoll } from '@fortawesome/free-solid-svg-icons';

import styles from './PollListItem.module.css';



const PollListItem = ({selected, poll}) => {

    return (
        <li id={styles.itemWrapper} onClick={()=>selected(poll)}>
         <span className={styles.icon}><FontAwesomeIcon icon={faPoll} /></span>
         <h1>{poll.question}</h1>
        </li>
    )
}

export default PollListItem;