import React, { useState, Fragment, useEffect } from 'react';
import PollChart from '../Charts/PollChart';

import styles from './PollItem.module.css';

const PollItem = ({ poll, close }) => {

  const [chartWidth, setChartWidth] = useState('30rem');

  useEffect(() => {
    poll && setCustomWidth(poll);
    console.log(poll);
    
  }, [])

  const setCustomWidth = data => {
    const columnCount = data.pollElements.length;
     const remWidth = (columnCount * 50) / 10;

     if ((columnCount > 5 ) && (columnCount < 7) ) {
        setChartWidth(`${remWidth + 50}rem`);
     } else if (columnCount >= 7) {
      setChartWidth(`${remWidth}rem`);
     }
  }

  return (
    <Fragment>
    
      <div className={styles.pollItemWrapper }>

        <div className={styles.headerMenuBar}>
       
        </div>
        <div className={styles.pollBodyContiner}>
         {poll && <PollChart poll={poll} width={chartWidth} />}
        </div>

        <div className='pim-total-votes'>

        </div>

        <div className={styles.footer}>
       
        </div>
      </div>
    </Fragment>
  );
};

export default PollItem;
