import { useState, useEffect} from 'react'
import { useParams } from 'react-router-dom';
import { useHttpClient } from '../../shared/hooks/http-hook';

export const useEventStatusCheck = () => {

    const { REACT_APP_API_URL } = process.env;
    const [eventData, setEventData] = useState();
    const { isLoading, error, sendRequest, clearError } = useHttpClient();
    const { eventExt } = useParams();

    const fetchEventStatus = async () => {
        console.log('checking status');
        try {
          const response = await sendRequest(
            `${REACT_APP_API_URL}/api/event/live-event-status-uri`,
            'POST',
            JSON.stringify({
              uri: eventExt
            }),
            {
              'Content-Type': 'application/json',
            }
            );
            console.log(response);
            if (response.event) {
                if (response.event !== eventData) {
                    setEventData(response.event)
                    localStorage.setItem("eventStatusData", JSON.stringify(response.event));
                }
            }
        }
        catch (err) {
          console.log(err);
        }
  
      }

      useEffect(() => {
        eventExt && fetchEventStatus();
      }, [eventExt])

      useEffect(() => {
        const timer=setTimeout(() => {
            fetchEventStatus();
        }, 30000);
        // Clear timeout if the component is unmounted
        return () => clearTimeout(timer);
      });

    return {loading:{isLoading}, eventData, statusError:{error}, clearStatusError:{clearError}};
};
