import React, { useState, useEffect, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import Button from '../UIElements/Buttons/Button';
import ContentContainer from '../UIElements/ContentContainer';
import styles from './PollVote.module.css';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import LoadingDots from '../UIElements/LoadingDots';
import ErrorModal from '../UIElements/Modal/ErrorModal';

const PollVote = ({ poll, isOpen }) => {
  const { REACT_APP_API_URL } = process.env;
  const { userId, token } = useContext(AuthContext);
  const { isLoading, sendRequest, error, clearError } = useHttpClient();

  const [voted, setVoted] = useState(false);
  const [votedValue, setVotedValue] = useState();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    console.log(poll);
    if (poll) {
      const elements = poll.pollElements.sort( compare );
      elements.length > 0 && setOptions(elements);
    }
    
    const logs = poll.pollLogs || '';
    if (logs !== '') {
      if (poll.pollLogs.length > 0) {
        const userVote = poll.pollLogs.find(vote => {
          return vote.userId === userId && vote.pollId === poll.id;
        });

        if (userVote) {
          setVoted(true);
          const userVoteValue = poll.pollElements.find(option => {
            return option.id === userVote.pollElementId;
          });

          userVoteValue && setVotedValue(userVoteValue.option);
        } else {
          setVoted(false);
        }
      } else {
        console.log('no logs');
        setVoted(false);
      }
    } else {
      setVoted(false);
    }
  }, [poll]);

  const voteCast = async option => {
    console.log(option.option);

    try {
      const responseData = await sendRequest(
        `${REACT_APP_API_URL}/api/poll/vote/${option.id}`,
        'PATCH',
        JSON.stringify({
          userId: userId,
          pollId: poll.id,
        }),
        {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + token,
        }
      );
      if (responseData) {
        setVoted(true);
        setVotedValue(option.option);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function compare( a, b ) {
    if ( a.label < b.label ){
      return -1;
    }
    if ( a.label > b.label ){
      return 1;
    }
    return 0;
  }
  
  

  return (
    <div className={styles.votingWrapper}>
      <ErrorModal error={error} onClear={clearError} />
      {isLoading && (
        <div>
          <LoadingDots />
        </div>
      )}
      {!isLoading && voted && (
        <ContentContainer
          classNameCC={styles.contentContainerWrapper}
          className={styles.thanksContainer}
          title=""
        >
          <span className={styles.check}>
            <FontAwesomeIcon icon={faCheckCircle} />
          </span>
          <h1 className={styles.message}>Your vote has been cast!</h1>
          <p className={styles.subMessage}> You can only vote once per poll</p>
          <h1 className={styles.message}>
              {poll.question}
            </h1>
          {votedValue && <h2 className={styles.votedValue}> {votedValue}</h2>}
        </ContentContainer>
      )}
      {isOpen && !voted && !isLoading && (
        <ContentContainer
          classNameCC={styles.contentContainerWrapper}
          className={styles.buttonContainer}
          title=""
        >
          <div className={styles.voteText}>
            <h1 className={styles.message}>
              {poll.question}
            </h1>
            <p className={styles.subMessage}>
              {' '}
              Choose an option and place your vote! <br/> You can only vote once per poll
            </p>
          </div>
          {options.length > 0 &&
            options.map(option => (
              <div key={option.id} className={styles.voteBtnContainer}>
                <Button  
                  className={styles.voteButton}
                  title={option.option}
                  onClick={() => voteCast(option)}
                />
                <div className={styles.voteValue}>
                 {option.value ? <p>{option.value}%</p> : <p>0%</p>}
                </div>
              </div>
            ))}
        </ContentContainer>
      )}
      {!isOpen && !voted && !isLoading &&
        <ContentContainer
          classNameCC={styles.contentContainerWrapper}
          className={styles.thanksContainer}
          title=""
        >
          <span className={styles.cross}>
            <FontAwesomeIcon icon={faTimesCircle} />
          </span>
          <h1 className={styles.message}>Voting is Closed!</h1>
          {votedValue && <h2 className={styles.votedValue}> {votedValue}</h2>}
        </ContentContainer>
      }
    </div>
  );
};

export default PollVote;
