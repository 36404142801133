import  { createContext } from 'react';

export const EventContext = createContext({
  eventId: null,
  title: null,
  subTitle: null,
  uri:null,
  date:null,
  open:false,
  supportEmail:null,
  createEvent: () => {},
  clearEvent: () => {},
});
