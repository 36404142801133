import React from 'react'
import logoSvg from '../../../img/logo-primary.png';
import styles from './Logo.module.css';

const Logo = () => {
    return (
        <div id={styles.logo}>
            <img src={logoSvg} width={150} />
        </div>
    )
}

export default Logo
