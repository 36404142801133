import React from 'react'
import { motion } from "framer-motion";

import styles from './RegionList.module.css';
import LangItem from './LangItem';

const container = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2
      }
    }
  };
  
  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1
    }
  };

const RegionList = ({regions, SelectedLang}) => {
    return (
        <motion.ul
    className="container"
    variants={container}
    initial="hidden"
    animate="visible"
  >
    {regions.map((region) => (
      <motion.li key={region.name} className={styles.listItem} variants={item} onClick={()=>SelectedLang(region.lang)}><LangItem region={region}/></motion.li> 
    ))}
  </motion.ul>
    )
}

export default RegionList
