import React from 'react';

import './ContentContainer.css';

const ContentContainer = ({children, title, classNameCC, className}) => {
  return (
    <section id="content-container" className={classNameCC}>
      <div className={`cc-outer-content`}>
        <h1 className="trans-details-title">{title}</h1>
        <div className={`cc-inner-content ${className}`}>
            {children}
        </div>
      </div>
    </section>
  );
};

export default ContentContainer;
