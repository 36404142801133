import { useState, useCallback, useEffect } from 'react';

export const useEvent = () => {
  const [eventId, setEventId] = useState(null);
  const [title, setTitle] = useState(null);
  const [subTitle, setSubTitle] = useState(null);
  const [uri, setUri] = useState(null);
  const [date, setDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [supportEmail, setSupportEmail] = useState(null);
  
  const createEvent = useCallback((id, title, subTitle, uri, date, open, supportEmail) => {
    setEventId(()=>id);
    setTitle(()=>title);
    setSubTitle(()=>subTitle);
    setUri(()=>uri);
    setDate(()=>date);
    setOpen(()=>open);
    setSupportEmail(()=>supportEmail);
    console.log('setting Event Context');
    
    localStorage.setItem(
        'eventData',
        JSON.stringify({
          eventId: id,
          title: title,
          subTitle: subTitle,
          uri: uri,
          date:date,
          open: open,
          supportEmail
        })
      );
    },
    []
  );

  const clearEvent = useCallback(() => {
    setEventId(null);
    setTitle(null);
    setSubTitle(null);
    setUri(null);
    setDate(null);
    setOpen(null);
    setSupportEmail(null);
    localStorage.removeItem('eventData');
  }, []);

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem('eventData'));
    storedData && createEvent(
    storedData.eventId,
    storedData.title,
    storedData.subTitle,
    storedData.uri,
    storedData.date,
    storedData.open,
    storedData.supportEmail
    );
    
  }, [createEvent]);

  return { eventId, title, subTitle, uri, date, open, supportEmail, createEvent, clearEvent };
};
