import React, {useEffect, useState} from 'react'

import styles from './CountDownComplete.module.css';

const CountDownComplete = ({eventDate}) => {

    const [postEvent, setPostEvent] = useState(false);

    useEffect(() => {

        var date = new Date("2020-07-12 11:30:10");
        var now = new Date();
        var diffInMS = now - date;
        var msInHour = Math.floor(diffInMS/1000/60);
        if (msInHour < 60) {
            setPostEvent(false);
        } else {
            setPostEvent(true);
        }
        
    }, [eventDate])


    return (
        <div id={styles.complete}>
            <div className={styles.leftContainer}>
                <h1 className={styles.mainTitle}>{postEvent ? 'The Event Is Now Closed.' : 'The Event Will Open Shortly.'}</h1>
            </div>
            <div className={styles.rightContainer}>
                <p className={styles.subTitle}>{postEvent ? 'Thank you!' : 'Thanks you for your patients.'}</p>
            </div>
           
        </div>
    )
}

export default CountDownComplete
