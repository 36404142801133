import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {useEffect, useState, useContext} from 'react';
import Marquee from "react-fast-marquee";
//import io from 'socket.io-client';
import { SocketContext } from '../../context/socket';

import { AuthContext } from '../../context/auth-context';
import { useHttpClient } from '../../hooks/http-hook';

import styles from './NewsMarquee.module.css';

const NewsMarquee = ({eventId}) => {


    const { REACT_APP_API_URL } = process.env;

    //const socket = io(REACT_APP_API_URL);
    const socket = useContext(SocketContext);

    const auth = useContext(AuthContext);
    const {sendRequest } = useHttpClient();

    const [newsToAdd, setNewsToAdd] = useState();  
    const [newsToRemove, setNewsToRemove] = useState();  
    const [update, setUpdate] = useState(false);  

    const [newsPosts, setNewsPosts] = useState([]);
    //console.log(eventId);

    useEffect(() => {
        newsToAdd && addNews(newsToAdd);
    }, [newsToAdd])

    useEffect(() => {
        newsToRemove && removeNews(newsToRemove);
    }, [newsToRemove])

    useEffect(() => {
        socket.on('news', dataFromServer => {
          updateNews(dataFromServer);
        });
        return () => {
          socket.off('news');
        }
    }, [socket]);

    const updateNews = data => {
        //console.log(data.action);
        if (data.action) {
            setNewsToAdd(data.news)
        } else {
            setNewsToRemove(data.news)
        }
      };

    useEffect(() => {
        const fetchNews = async () => {
            try {
                const response = await sendRequest(
                    `${REACT_APP_API_URL}/api/announcement/live/${eventId}`,
                    'POST',
                    {
                      'Content-Type': 'application/json',
                      Authorization: 'Bearer ' + auth.token,
                    }
                  );

                  if (response){
                      if (response.news) {
                        setNewsPosts(response.news);
                      }
                  }
                  
            } catch (error) {
                //console.log(error);
            }
        }
        eventId && fetchNews();
    }, [update])

    const addNews = newsPost => {
      // const duplicate = newsPosts.find(n => n.id === newsPost.id);
      //  !duplicate && setNewsPosts(currentPosts => [...currentPosts, newsPost]);
       setUpdate(update ? false : true);
    }

    const removeNews = newsPost => {
      // if (newsPosts.length === 1) {
      //   setNewsPosts([])
      // } else {
      //   let updatedPosts = newsPosts;
      //   const idx = updatedPosts.findIndex(np => np.id === newsPost.id);
      //   idx && updatedPosts.splice(idx, 1);
      //   setNewsPosts(updatedPosts);
      // }

      setUpdate(update ? false : true);
       
    }


  return (
    <section id={styles.MarqueeWrapper}>
      {newsPosts && 
        <Marquee 
            gradient={false} 
            speed={50}>
            {newsPosts && newsPosts.map(news => (
                <div key={news.id} className={styles.newsItem}>
                    <span className={styles.seperator}><FontAwesomeIcon icon={faBullhorn}/></span>
                    <p className={styles.newsText}>{news.message}</p>
                </div>
            ))}
        </Marquee>
      }
    </section>
  );
};

export default NewsMarquee;
