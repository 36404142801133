import React from 'react';
import {useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import styles from './BackButton.modules.css'

const BackButton = () => {
    const { eventExt } = useParams()
    const { t } = useTranslation();
    
    return (
        <div className={styles.backWrapper}>
        <Link to={`/${eventExt}`}>
            <FontAwesomeIcon icon={faArrowLeft}/> { t('back to login')}
          </Link>
        </div>
    )
}

export default BackButton;